import Link from 'next/link';
import MyProjectsList from '@/components/sections/MyProjectsList';

export default function RecentDesignsBlock({ projectsData }) {
  return (
    <>
      <div className="home-projects-block">
        <h3>
          My Recent Designs{' '}
          <Link href="/my-designs">
            <a className="link-button">View More</a>
          </Link>
        </h3>
        {projectsData && <MyProjectsList projects={projectsData} />}
      </div>
    </>
  );
}
