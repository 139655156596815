import Link from 'next/link';
import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { checkImgUrl } from '@/datalayer/config';
import { ReactSVG } from 'react-svg';
import { useSiteInfo } from '@/context/SiteInfoContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CategoryList = ({ featuredCategories }) => {
  const { siteInfo } = useSiteInfo();

  const primaryColor = siteInfo.primary_color;
  const secondaryColor = siteInfo.secondary_color;

  const finalCategories = useMemo(() => {
    return featuredCategories.filter(
      (item) => !item.parent && item.category_icon,
    );
  }, []);

  const getSlidesLength = (arrLength, desiredLength) => {
    if (arrLength >= desiredLength) return desiredLength;
    if (arrLength < desiredLength) return arrLength;
  };

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    slidesToShow: getSlidesLength(finalCategories.length, 8),
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: getSlidesLength(finalCategories.length, 2),
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: getSlidesLength(finalCategories.length, 3),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: getSlidesLength(finalCategories.length, 4),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: getSlidesLength(finalCategories.length, 6),
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: getSlidesLength(finalCategories.length, 7),
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {finalCategories.map((_category, i) => (
          <div key={i}>
            <div className={`template-single ${_category.slug}`}>
              <Link href={`/${_category.slug}`}>
                <a>
                  {_category.category_icon && (
                    <div className="template-single-image">
                      <ReactSVG
                        beforeInjection={(svg) => {
                          svg
                            .querySelectorAll('linearGradient')
                            .forEach((item) => {
                              if (secondaryColor)
                                item
                                  .querySelector('stop:first-child')
                                  .setAttribute('stop-color', secondaryColor);
                              if (primaryColor)
                                item
                                  .querySelector('stop:last-child')
                                  .setAttribute('stop-color', primaryColor);
                            });
                        }}
                        src={checkImgUrl(
                          _category.category_icon?.filename_disk ?? '',
                        )}
                      />
                    </div>
                  )}
                  <h4>{_category.category_name}</h4>
                </a>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default CategoryList;
