import React from 'react';
import ProductsList from '@/components/sections/ProductsList';

const ProjectsBlock = ({ products, title, subtitle }) => {
  return (
    <div className="home-products-block">
      <h3>{title}</h3>
      <p>
        {subtitle}
        {/* <Link href="/my-designs"><a className="link-button">View More</a></Link> */}
      </p>
      <ProductsList products={products} />
    </div>
  );
};

export default ProjectsBlock;
