import Image from 'next/image';
import kosheraiblockimg from '@/images/homepage/kosherai-block-gif.gif';
import ai from '@/images/homepage/ai.svg';
import { ReactSVG } from 'react-svg';

const KosherAIBlock = () => {
  return (
    <>
      <div className="kosherai-block p-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="kosherai-blockinner">
                <div className="kosherai-block-right">
                  <h4>
                    <span>
                      <ReactSVG src={ai.src} />
                    </span>{' '}
                    Kosher <label> AI</label>
                  </h4>
                  <p>AI assistant to help with every design</p>
                </div>
                <div className="kosherai-block-left">
                  <Image alt="kosheraiblockimg" src={kosheraiblockimg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KosherAIBlock;
