import Link from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'context/AuthContext';
import { toast } from 'react-toastify';
import { getSubscriptionURL } from 'lib/stripeHelper';
import DiamondIcon from 'assets/jsx-icons/DiamondIcon';
import { ReactSVG } from 'react-svg';

import dashboard from '@/images/icons/dashboard.svg';
import autopay from '@/images/icons/autopay.svg';
import share from '@/images/icons/share.svg';
import tag from '@/images/icons/tag.svg';
import star from '@/images/icons/star.svg';
import resize from '@/images/icons/resize.svg';
import verified from '@/images/icons/verified.svg';
import support_agent from '@/images/icons/support_agent.svg';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { planDetails } from '@/constants/planDetails';
import { getDiscountedBaseDesignPrice } from 'utils/getDiscountedBaseDesignPrice';

const HomeKGFeaturesBlock = (products) => {
  const router = useRouter();
  const [doSubs, setDoSubs] = useState(false);

  const { openLoginModal, openRegisterModal, setCallback } = useAuth();
  const { siteInfo } = useSiteInfo();

  const discountedDesignPrice = getDiscountedBaseDesignPrice(
    products,
    siteInfo,
  );

  const handleLogin = async () => {
    if (doSubs) {
      setCallback(async () => {
        try {
          const url = await getSubscriptionURL();
          router.push(url);
        } catch (e) {
          toast.error(e);
        }
      });
    }
    openLoginModal();
  };

  const handleRegister = async () => {
    openRegisterModal();
  };

  const processSubs = async (e) => {
    e.preventDefault();
    setDoSubs(true);
    handleLogin();
  };

  return (
    <>
      <div className="kg-features mb-100 p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="kg-feature-single">
                <h4>Free</h4>
                <p>&nbsp;</p>
                <ul>
                  <li>
                    <span>
                      <ReactSVG src={dashboard.src} />
                    </span>
                    <span>850+ Templates and thousands of clipart</span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={autopay.src} />
                    </span>
                    <span>Magic Autofill</span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={share.src} />
                    </span>
                    <span>Share designs with friends</span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={tag.src} />
                    </span>
                    <span>
                      Dozens of templates 100% FREE, others only $
                      {discountedDesignPrice}
                    </span>
                  </li>
                </ul>

                <div className="kg-feature-btn">
                  <button
                    className="button-outlined lg"
                    onClick={handleRegister}
                  >
                    <span>Sign Up Now</span>
                  </button>
                </div>

                <p>
                  Already have an account?{' '}
                  <button className="purple-button" onClick={handleLogin}>
                    Login
                  </button>
                </p>
              </div>
              <div className="kg-feature-single">
                <h4>
                  <DiamondIcon />
                  <span>Pro</span>
                </h4>
                <p>Everything in Free plus..</p>
                <ul className="pro-block-ul">
                  <li>
                    <span>
                      <ReactSVG src={star.src} />
                    </span>
                    <span>
                      Unlimited downloads on all Flyers, Social media, Web
                      banners & more
                    </span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={resize.src} />
                    </span>
                    <span>Smart Resize. Resize a design to any size</span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={verified.src} />
                    </span>
                    <span>Unlimited saved designs</span>
                  </li>
                  <li>
                    <span>
                      <ReactSVG src={support_agent.src} />
                    </span>
                    <span>Priority Support</span>
                  </li>
                </ul>
                <span className="light-text pro-price-text bottom">
                  Only ${siteInfo?.pro_price || planDetails.monthly.price}
                  /month!
                </span>
                <div className="kg-feature-btn">
                  <button className="button-contained lg" onClick={processSubs}>
                    Subscribe Now
                  </button>
                </div>
                <p>
                  <Link href="/pro">
                    <a className="purple-button">Learn More</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeKGFeaturesBlock;
